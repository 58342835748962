var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('form-wizard', {
    staticClass: "mb-3",
    attrs: {
      "color": "#ae2012",
      "title": null,
      "subtitle": null,
      "shape": "square",
      "finish-button-text": "Submit",
      "back-button-text": "Previous"
    },
    on: {
      "on-complete": _vm.formSubmitted
    }
  }, [_c('tab-content', {
    attrs: {
      "title": "Data Diri",
      "before-change": _vm.validationForm
    }
  }, [_c('validation-observer', {
    ref: "dataDiri",
    attrs: {
      "tag": "form"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "mb-3"
  }, [_vm._v(" Halo " + _vm._s(_vm.user.name) + ", Selamat datang di Pendaftaran SNBP, Silakan isi data diri anda dengan benar")]), _vm._v(" "), _c('br')]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nama",
      "label-for": "nama"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "nama",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "nama",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Masukkan Nama"
          },
          model: {
            value: _vm.dataDiri.nama,
            callback: function callback($$v) {
              _vm.$set(_vm.dataDiri, "nama", $$v);
            },
            expression: "dataDiri.nama"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Asal Sekolah",
      "label-for": "asal_sekolah"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "asal_sekolah",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "asal_sekolah",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Masukkan Asal Sekolah",
            "disabled": ""
          },
          model: {
            value: _vm.dataDiri.asal_sekolah,
            callback: function callback($$v) {
              _vm.$set(_vm.dataDiri, "asal_sekolah", $$v);
            },
            expression: "dataDiri.asal_sekolah"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Akreditasi Sekolah",
      "label-for": "ak_sekolah"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Akreditasi Sekolah",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "ak_sekolah",
            "autocomplete": "off",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Masukkan Akreditasi Sekolah"
          },
          model: {
            value: _vm.dataDiri.ak_sekolah,
            callback: function callback($$v) {
              _vm.$set(_vm.dataDiri, "ak_sekolah", $$v);
            },
            expression: "dataDiri.ak_sekolah"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1), _c('tab-content', {
    attrs: {
      "title": "Raport",
      "before-change": _vm.validationFormRaport
    }
  }, [_c('validation-observer', {
    ref: "raport",
    attrs: {
      "tag": "form"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "mb-3"
  }, [_vm._v("Silakan Isi Nilai Raport dari Semester 1 sampai Semester 5")]), _vm._v(" "), _c('br')]), _c('b-col', [_c('center', [_c('hr'), _c('h3', [_vm._v("Raport")]), _c('hr')])], 1)], 1), _vm._l(_vm.mapelRaport, function (item, i) {
    return _c('b-row', {
      key: i
    }, [_c('b-col', {
      attrs: {
        "md": "12"
      }
    }, [_c('label', {
      attrs: {
        "for": ""
      }
    }, [_vm._v(_vm._s(item.nama_mapel))])]), _vm._l(item.semester, function (data, index) {
      return _c('b-col', {
        key: index,
        attrs: {
          "md": "2"
        }
      }, [_c('b-form-group', [_c('validation-provider', {
        attrs: {
          "name": "".concat(item.nama_mapel, " Semester  ").concat(data.type),
          "rules": "required|integer"
        },
        scopedSlots: _vm._u([{
          key: "default",
          fn: function fn(_ref4) {
            var errors = _ref4.errors;
            return [_c('b-form-input', {
              attrs: {
                "id": "nilai_raport",
                "autocomplete": "off",
                "placeholder": "Nilai Semester ".concat(data.type)
              },
              on: {
                "change": function change($event) {
                  return _vm.setRataRata(i, index);
                }
              },
              model: {
                value: data.nilai,
                callback: function callback($$v) {
                  _vm.$set(data, "nilai", $$v);
                },
                expression: "data.nilai"
              }
            }), _c('b-form-invalid-feedback', {
              attrs: {
                "state": errors.length > 0 ? false : null
              }
            }, [_vm._v(" " + _vm._s(errors[0]) + " ")])];
          }
        }], null, true)
      })], 1)], 1);
    }), _c('b-col', {
      attrs: {
        "md": "2"
      }
    }, [_c('b-form-group', [_c('validation-provider', {
      attrs: {
        "name": "Rata-rata ".concat(item.nama_mapel),
        "rules": "required"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref5) {
          var errors = _ref5.errors;
          return [_c('b-form-input', {
            attrs: {
              "readonly": "",
              "id": "rata-rata",
              "autocomplete": "off",
              "placeholder": "Rata-rata ".concat(item.nama_mapel)
            },
            model: {
              value: item.rata_rata,
              callback: function callback($$v) {
                _vm.$set(item, "rata_rata", $$v);
              },
              expression: "item.rata_rata"
            }
          }), _c('b-form-invalid-feedback', {
            attrs: {
              "state": errors.length > 0 ? false : null
            }
          }, [_vm._v(" " + _vm._s(errors[0]) + " ")])];
        }
      }], null, true)
    })], 1)], 1)], 2);
  })], 2)], 1), _c('tab-content', {
    attrs: {
      "title": "Piagam",
      "before-change": _vm.validationFormPiagam
    }
  }, [_c('validation-observer', {
    ref: "Piagam",
    staticClass: "repeater-form",
    attrs: {
      "tag": "form"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.repeatePiagamAgain($event);
      }
    }
  }, [_c('h4', {
    staticClass: "mb-0"
  }, [_c('b', [_vm._v(" Silahkan isi piagam ("), _c('i', {
    staticClass: "text-danger"
  }, [_vm._v("opsional")]), _vm._v(") ")])]), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("*maksimal 3 piagam ")]), _vm._l(_vm.itemsPiagam, function (item, index) {
    return _c('b-row', {
      key: item.id,
      ref: "rowPiagam",
      refInFor: true,
      staticClass: "mt-2",
      attrs: {
        "id": item.id
      }
    }, [_c('b-col', {
      attrs: {
        "md": "6"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Nama Piagam",
        "label-for": "piagam"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": "Nama Piagam",
        "rules": ""
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref6) {
          var errors = _ref6.errors;
          return [_c('b-form-input', {
            attrs: {
              "id": "nilai_raport",
              "state": errors.length > 0 ? false : null,
              "placeholder": "Masukkan Nama Piagam "
            },
            model: {
              value: item.nama_piagam,
              callback: function callback($$v) {
                _vm.$set(item, "nama_piagam", $$v);
              },
              expression: "item.nama_piagam"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1), _c('b-col', {
      attrs: {
        "md": "6"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "File Piagam",
        "label-for": "piagam"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": "File Piagam",
        "rules": ""
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref7) {
          var errors = _ref7.errors;
          return [_c('b-form-file', {
            attrs: {
              "state": errors.length > 0 ? false : null,
              "placeholder": "Pilih file untuk upload piagam",
              "drop-placeholder": "Drop file here..."
            },
            model: {
              value: item.file_piagam,
              callback: function callback($$v) {
                _vm.$set(item, "file_piagam", $$v);
              },
              expression: "item.file_piagam"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1)], 1);
  })], 2), _c('center', [_vm.itemsPiagam.length <= 2 ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-1",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.repeatePiagamAgain
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "PlusIcon"
    }
  }), _c('span', [_vm._v("Tambah")])], 1) : _vm._e(), _vm.itemsPiagam.length >= 2 ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-1 ml-1",
    attrs: {
      "variant": "outline-danger"
    },
    on: {
      "click": _vm.removePiagamItem
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "TrashIcon"
    }
  }), _c('span', [_vm._v("Hapus")])], 1) : _vm._e()], 1)], 1), _c('tab-content', {
    attrs: {
      "title": "Prodi Pilihan",
      "before-change": _vm.validationFormProdi
    }
  }, [_c('validation-observer', {
    ref: "prodiPilihan",
    staticClass: "repeater-form",
    attrs: {
      "tag": "form"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.repeateAgain($event);
      }
    }
  }, [_c('h4', {
    staticClass: "mb-0"
  }, [_c('b', [_vm._v(" Silahkan pilih univertas dan jurusan yang dituju")])]), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("*maksimal 2 pilihan ")]), _vm._l(_vm.items, function (item, index) {
    return _c('b-row', {
      key: item.id,
      ref: "row",
      refInFor: true,
      staticClass: "mt-2",
      attrs: {
        "id": item.id
      }
    }, [_c('b-col', {
      attrs: {
        "md": "12"
      }
    }, [_c('center', [_c('hr'), _c('h3', [_vm._v("PILIHAN " + _vm._s(item.no))]), _c('hr')])], 1), _c('b-col', {
      attrs: {
        "md": "6"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Universitas",
        "label-for": "universitas"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": "Universitas",
        "rules": "required"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref8) {
          var errors = _ref8.errors;
          return [_c('v-select', {
            attrs: {
              "id": "universitas",
              "options": _vm.universitasName,
              "label": "text",
              "placeholder": "Pilih Universitas"
            },
            on: {
              "input": function input($event) {
                return _vm.fetchDataJurusan(index);
              }
            },
            model: {
              value: item.universitas,
              callback: function callback($$v) {
                _vm.$set(item, "universitas", $$v);
              },
              expression: "item.universitas"
            }
          }), _c('b-form-invalid-feedback', {
            attrs: {
              "state": errors.length > 0 ? false : null
            }
          }, [_vm._v(" " + _vm._s(errors[0]) + " ")])];
        }
      }], null, true)
    })], 1)], 1), _c('b-col', {
      attrs: {
        "md": "6"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Jurusan",
        "label-for": "jurusan"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": "Jurusan",
        "rules": "required"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref9) {
          var errors = _ref9.errors;
          return [_c('v-select', {
            attrs: {
              "id": "jurusan",
              "options": item.jurusanName,
              "label": "text",
              "placeholder": "Pilih Jurusan"
            },
            model: {
              value: item.jurusan,
              callback: function callback($$v) {
                _vm.$set(item, "jurusan", $$v);
              },
              expression: "item.jurusan"
            }
          }), _c('b-form-invalid-feedback', {
            attrs: {
              "state": errors.length > 0 ? false : null
            }
          }, [_vm._v(" " + _vm._s(errors[0]) + " ")])];
        }
      }], null, true)
    })], 1)], 1), _c('b-col', {
      attrs: {
        "md": "12"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Alumni",
        "label-for": "alumni"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": "Alumni",
        "rules": "required"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref10) {
          var errors = _ref10.errors;
          return [_c('b-form-select', {
            attrs: {
              "id": "alumni",
              "options": _vm.alumniOption,
              "state": errors.length > 0 ? false : null
            },
            model: {
              value: item.alumni,
              callback: function callback($$v) {
                _vm.$set(item, "alumni", $$v);
              },
              expression: "item.alumni"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1), item.alumni && item.alumni == 'ada' ? _c('b-col', {
      attrs: {
        "md": "12"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Jumlah Alumni",
        "label-for": "ak_sekolah"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": "Jumlah Alumni",
        "rules": "required|integer"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref11) {
          var errors = _ref11.errors;
          return [_c('b-form-input', {
            attrs: {
              "id": "jumlah_alumni",
              "state": errors.length > 0 ? false : null,
              "placeholder": "Masukkan Jumlah Alumni"
            },
            model: {
              value: item.jumlah_alumni,
              callback: function callback($$v) {
                _vm.$set(item, "jumlah_alumni", $$v);
              },
              expression: "item.jumlah_alumni"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1) : _vm._e()], 1);
  })], 2), _c('center', [_vm.items.length <= 1 ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-1",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.repeateAgain
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "PlusIcon"
    }
  }), _c('span', [_vm._v("Tambah")])], 1) : _vm._e(), _vm.items.length > 1 ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-1 ml-1",
    attrs: {
      "variant": "outline-danger"
    },
    on: {
      "click": _vm.removeItem
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "TrashIcon"
    }
  }), _c('span', [_vm._v("Hapus")])], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }